var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mt-5",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"title-section"},[_vm._v(" ACTAS DE REUNIÓN ")])]),_c('v-col',{attrs:{"cols":"3"}},[_c('v-icon',{attrs:{"color":"black","large":""}},[_vm._v("mdi-clipboard-text-outline")]),_c('b',[_vm._v("Actas de Reunión")])],1),_c('v-col',{attrs:{"cols":"6","align":"center"}},[(_vm.isAdmin || _vm.isAmbUser)?_c('v-btn',{staticClass:"normal-btn mr-2",attrs:{"color":"primary","depressed":""},on:{"click":_vm.showCreateDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" Crear Acta ")],1):_vm._e(),_c('v-btn',{staticClass:"normal-btn",attrs:{"color":"black","dark":"","depressed":""},on:{"click":_vm.goToVideo}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-video-outline")]),_vm._v(" Videollamadas ")],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('kimsa-text-field',{attrs:{"placeholder":"Buscar","append-icon":"mdi-magnify","outlined":"","dense":"","solo":"","flat":""},on:{"change":function($event){_vm.search = $event}}})],1)],1),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"loading":_vm.loading,"search":_vm.search,"items":_vm.meetings,"items-per-page":5},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-account-circle-outline")]),_c('b',{domProps:{"textContent":_vm._s(item.client)}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',{domProps:{"textContent":_vm._s(item.name)}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-calendar-outline")]),_vm._v(" "+_vm._s(item.date)+" ")]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-map-marker-outline")]),_c('b',{domProps:{"textContent":_vm._s(item.location)}})]}},{key:"item.tasks",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pin-outline")]),_c('b',{domProps:{"textContent":_vm._s(item.tasks)}})]}},{key:"item.participants",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-account-outline")]),_c('b',{domProps:{"textContent":_vm._s(item.participants)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1 my-1",attrs:{"fab":"","outlined":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),(_vm.isAdmin)?_c('v-btn',{staticClass:"mr-1 my-1",attrs:{"fab":"","outlined":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-delete")])],1):_vm._e(),_c('v-btn',{staticClass:"mr-1 my-1",attrs:{"fab":"","outlined":"","x-small":"","loading":item.loadingPdf},on:{"click":function($event){$event.stopPropagation();return _vm.downloadPdf(item)}}},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-download")])],1)]}}])}),_c('create-meeting-dialog',{ref:"creator",on:{"success":_vm.loadMeetings}}),_c('kimsa-confirm',{ref:"confirm",attrs:{"title":"Eliminar","content":"¿Esta seguro que desea eliminar esta Acta de Reunión?","persistent":"","loading":_vm.loadingDelete,"no-close-on-confirm":""},on:{"confirm":_vm.onConfirmDelete,"cancel":function($event){_vm.currentId = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
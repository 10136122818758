<template>
    <v-container>

        <!-- HEADER -->
        <v-row class="mt-5" align="center">
            <v-col cols="12">
                <div class="title-section">
                    ACTAS DE REUNIÓN
                </div>
            </v-col>
            <v-col cols="3">
                <v-icon color="black" large>mdi-clipboard-text-outline</v-icon>
                <b>Actas de Reunión</b>
            </v-col>
            <v-col cols="6" align="center">
                <v-btn v-if="isAdmin || isAmbUser" class="normal-btn mr-2" color="primary" depressed
                    @click="showCreateDialog">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    Crear Acta
                </v-btn>
                <v-btn color="black" dark depressed class="normal-btn" @click="goToVideo">
                    <v-icon left>mdi-video-outline</v-icon>
                    Videollamadas
                </v-btn>
            </v-col>
            <v-col cols="3">
                <kimsa-text-field placeholder="Buscar" append-icon="mdi-magnify" outlined dense solo flat
                    @change="search = $event" />
            </v-col>
        </v-row>

        <!-- TABLE -->
        <v-data-table class="mt-5" :headers="headers" :loading="loading" :search.sync="search" :items="meetings"
            :items-per-page="5">
            <template v-slot:item.client="{ item }">
                <v-icon>mdi-account-circle-outline</v-icon>
                <b v-text="item.client"></b>
            </template>
            <template v-slot:item.name="{ item }">
                <b v-text="item.name"></b>
            </template>
            <template v-slot:item.date="{ item }">
                <v-icon>mdi-calendar-outline</v-icon>
                {{ item.date }}
            </template>
            <template v-slot:item.location="{ item }">
                <v-icon left>mdi-map-marker-outline</v-icon>
                <b v-text="item.location"></b>
            </template>
            <template v-slot:item.tasks="{ item }">
                <v-icon left>mdi-pin-outline</v-icon>
                <b v-text="item.tasks"></b>
            </template>
            <template v-slot:item.participants="{ item }">
                <v-icon>mdi-account-outline</v-icon>
                <b v-text="item.participants"></b>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn class="mr-1 my-1" fab outlined x-small @click.stop="editItem(item)">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="isAdmin" class="mr-1 my-1" fab outlined x-small @click.stop="deleteItem(item)">
                    <v-icon color="primary">mdi-delete</v-icon>
                </v-btn>
                <v-btn class="mr-1 my-1" fab outlined x-small @click.stop="downloadPdf(item)"
                    :loading="item.loadingPdf">
                    <v-icon color="info">mdi-download</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <!-- COMPONENTS -->
        <create-meeting-dialog @success="loadMeetings" ref="creator" />

        <kimsa-confirm title="Eliminar" content="¿Esta seguro que desea eliminar esta Acta de Reunión?" persistent
            @confirm="onConfirmDelete" @cancel="currentId = null" :loading="loadingDelete" no-close-on-confirm
            ref="confirm" />

    </v-container>
</template>

<script>
import Axios from "@/utils/Axios";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import KimsaConfirm from "@/components/common/KimsaConfirm";
import CreateMeetingDialog from "@/components/meeting/CreateMeetingDialog";

export default {
    name: "Meetings",
    components: { CreateMeetingDialog, KimsaConfirm, KimsaTextField },
    data() {
        return {
            loading: false,
            meetings: [],
            search: '',
            currentId: null,
            loadingDelete: false,
            headers: [
                { text: 'Cliente', value: 'client', class: 'grey white--text' },
                { text: 'Título', value: 'name', class: 'grey white--text' },
                { text: 'Fecha', value: 'date', class: 'grey white--text' },
                { text: 'Ubicación', value: 'location', class: 'grey white--text' },
                { text: 'Participantes', value: 'participants', class: 'grey white--text' },
                { text: '', value: 'actions', sortable: false, class: `grey` },
            ],
        }
    },
    mounted() {
        const vm = this
        vm.loadMeetings()
    },
    methods: {
        async loadMeetings(payload = {}) {
            const vm = this
            vm.loading = true

            await Axios.post('meeting-records', payload).then(res => {
                // console.log('res loadMeetings',res)
                vm.meetings = res.data.result.meetingRecords.map(meeting => {
                    return {
                        id: meeting.id,
                        client: meeting.client?.name || 'n/d',
                        name: meeting.name,
                        date: meeting.date ?
                            vm.$moment(meeting.date).format('DD/MM/YYYY') :
                            vm.$moment(meeting.created_at).format('DD/MM/YYYY'),
                        location: meeting.location || 'n/d',
                        tasks: vm.loadQuantity(meeting.tasks),
                        participants: vm.meetingParticipants(meeting.participants),
                        loadingPdf: false,
                    }
                })
            }).catch(er => {
                console.log('error loadMeetings', er)
            }).finally(() => {
                vm.loading = false
            })
        },
        loadQuantity(array) {
            let quantity = array && array.length || 0
            return quantity < 10 ? `0${quantity}` : quantity
        },
        meetingParticipants(participants) {
            let quantity = participants && participants.length || 0
            if (quantity === 0) return 'n/d'
            return quantity > 1 ? `${participants[0].name}+${quantity - 1}` : participants[0].name
        },
        showCreateDialog() {
            const vm = this
            vm.$refs.creator.show()
        },
        editItem(item) {
            const vm = this
            vm.$refs.creator.loadMeeting(item.id)
        },
        deleteItem(item) {
            const vm = this
            vm.$refs.confirm.show()
            vm.currentId = item.id
        },
        async onConfirmDelete() {
            const vm = this
            vm.loadingDelete = true

            await Axios.delete(`meeting-record/${vm.currentId}/delete`).then(res => {
                // console.log('res onConfirmDelete',res)
                vm.toast('Acta de reunión eliminada')
            }).catch(er => {
                console.log('er', er)
                vm.toast('error onConfirmDelete', er)
            })

            vm.loadingDelete = false
            vm.currentId = null

            vm.$refs.confirm.hide()
            await vm.loadMeetings()
        },
        goToVideo() {
            const vm = this
            vm.$router.push({ name: 'meetings.videochat' })
        },
        async downloadPdf(meeting) {
            const vm = this
            console.log('meeting', meeting)
            meeting.loadingPdf = true

            let url = `meeting-record/${meeting.id}/pdf`

            await Axios.get(url).then(res => {
                console.log('res pdfItem', res)
                const pdf = res.data.result.pdf
                const linkSource = `data:application/pdf;base64,${pdf}`;
                const downloadLink = document.createElement("a");
                const fileName = `${meeting.name} - ${meeting.client}.pdf`;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }).catch(er => {
                console.log('er', er)
                vm.toast(`error downloadPDF: ${er}`, 'error')
            }).finally(() => {
                meeting.loadingPdf = false
            })
        }
    }
}
</script>

<style scoped>
.title-section {
    color: var(--v-primary-base);
}
</style>